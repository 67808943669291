import $ from 'jquery'

$(document).ready(dhsv_vc_before_after_slider())

function dhsv_vc_before_after_slider() {
  let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints
  $('.before-after').each(function() {
    let $container = $(this),
      $before = $container.find('.before'),
      // $after = $container.find('.after'),
      $handle = $container.find('.handle')

    let maxX = $container.outerWidth(),
      // startX = 0,
      offsetX = $container.offset().left
    let touchstart, touchmove, touchend
    let mousemove = function(e) {
      e.preventDefault()
      let curX = e.clientX - offsetX,
        // diff = startX - curX,
        curPos = curX / maxX * 100
      if (curPos > 100) {
        curPos = 100
      }
      if (curPos < 0) {
        curPos = 0
      }
      $before.css({ right: 100 - curPos + '%' })
      $handle.css({ left: curPos + '%' })
    }
    let mouseup = function(e) {
      e.preventDefault()
      if (supportsTouch) {
        $(document).off('touchmove', touchmove)
        $(document).off('touchend', touchend)
      } else {
        $(document).off('mousemove', mousemove)
        $(document).off('mouseup', mouseup)
      }
    }
    let mousedown = function(e) {
      e.preventDefault()
      //startX = e.clientX - offsetX
      if (supportsTouch) {
        $(document).on('touchmove', touchmove)
        $(document).on('touchend', touchend)
      } else {
        $(document).on('mousemove', mousemove)
        $(document).on('mouseup', mouseup)
      }
    }

    touchstart = function(e) {
      mousedown({
        preventDefault: e.preventDefault,
        clientX: e.originalEvent.changedTouches[0].pageX,
      })
    }
    touchmove = function(e) {
      mousemove({
        preventDefault: e.preventDefault,
        clientX: e.originalEvent.changedTouches[0].pageX,
      })
    }
    touchend = function(e) {
      mouseup({
        preventDefault: e.preventDefault,
        clientX: e.originalEvent.changedTouches[0].pageX,
      })
    }
    if (supportsTouch) {
      $handle.on('touchstart', touchstart)
    } else {
      $handle.on('mousedown', mousedown)
    }
  })
}
