import $ from 'jquery'
import Flickity from 'flickity'

$(document).ready(dhsv_vc_testimonials_slider())

function dhsv_vc_testimonials_slider() {
  $('.dhsv_vc_testimonials_slider').each(function() {
    let slider = $(this).find('.testimonials_slider')[0]
    const flkty = new Flickity(slider, {
      draggable: '>1',
      wrapAround: true,
      pageDots: false,
    })
    flkty

    $('.testimonial-item__js').on('click', function(e) {
      e.preventDefault()
      $('.modal-opened-js').fadeOut()
      $('.modal-closed-js').fadeIn()
      $('.dhsv_vc_testimonials_slider').addClass('active')
      setTimeout(function() {
        flkty.resize()
      }, 1000)
    })

    $('.testimonial-item-close-js').on('click', function(e) {
      e.preventDefault()
      $('.modal-opened-js').fadeIn()
      $('.modal-closed-js').fadeOut()
      $('.dhsv_vc_testimonials_slider').removeClass('active')
      setTimeout(function() {
        flkty.resize()
      }, 1000)
    })
  })
}
