import $ from 'jquery'
import Flickity from 'flickity/dist/flickity.pkgd.min'

$(document).ready(() => {
  dhsv_vc_cases()
  dhsv_vc_cases_sliders()
})

function dhsv_vc_cases() {
  $(document).on('click', '.reference-js', function(e) {
    e.preventDefault()
    let ind = $(this)
        .parents('.references-b-logos__item, .references-item-js')
        .index(),
      cases = $(this).parents('.dhsv_vc_cases')

    cases
      .find('.references-modal')
      .eq(ind)
      .slideDown()
    cases.find('.references').slideUp()
    cases.find('.references-b-logos').slideUp()
    cases.find('.references-b-info').slideUp()
    cases.find('.references-button').slideUp()
    cases.prev().slideUp()
    cases.next().slideUp()
  })

  $(document).on('click', '.reference-close-js', function(e) {
    e.preventDefault()
    let cases = $(this).parents('.dhsv_vc_cases')
    cases.find('.references-modal').slideUp()
    cases.find('.references').slideDown()
    cases.find('.references-b-logos').slideDown()
    cases.find('.references-b-info').slideDown()
    cases.find('.references-button').slideDown()
    cases.prev().slideDown()
    cases.next().slideDown()
  })
}

function dhsv_vc_cases_sliders() {
  $('.dhsv_vc_cases').each(() => {
    if ($('.references-b-logos').length > 0) {
      let logosSl = $(this).find('.references-b-logos').selector,
        infoSl = $(this).find('.references-b-info').selector
      const flkty = new Flickity(logosSl, {
        draggable: true,
        pageDots: false,
        prevNextButtons: false,
        asNavFor: infoSl,
        wrapAround: false,
        cellAlign: 'left',
        contain: false,
        groupCells: 1,
      })

      const flktySecond = new Flickity(infoSl, {
        draggable: true,
        pageDots: false,
        wrapAround: false,
        fade: true,
      })

      $(window).on('resize', () => {
        $(this)
          .find('.references-b-logos .flickity-viewport')
          .css('height', '')
        flkty.resize()
        $(this)
          .find('.references-b-info .flickity-viewport')
          .css('height', '')
        flktySecond.resize()
      })
    }
  })
}
