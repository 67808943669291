import $ from 'jquery'
import { TweenMax } from 'gsap/all'

$(document).ready(() => {
  dhsv_vc_contact_animation()
})

function dhsv_vc_contact_animation() {
  $('.dhsv_vc_contact_teaser').each(function() {
    $(this).mousemove(function(e) {
      let circleInner = $(this).find('.contact-teaser-image__outer')
      parallaxIt(e, circleInner[0], -30)
    })
  })

  function parallaxIt(e, target, movement) {
    let $this = $('.contact-teaser-image__outer'),
      relX = e.pageX - $this.offset().left,
      relY = e.pageY - $this.offset().top
    console.log($this.offset().left) //eslint-disable-line
    TweenMax.to(target, 1, {
      x: (relX - $this.width() / 2) / $this.width() * movement,
      y: (relY - $this.height() / 2) / $this.height() * movement,
    })
  }
}
