import cssVars from 'css-vars-ponyfill'
import { TweenMax } from 'gsap/all'

const dhsvCommon = {
  parallaxCircle() {
    $('.circle, .circle_right').each(function() {
      $(this).mousemove(function(e) {
        let circleInner = $(this).find('.circle-inner')
        parallaxIt(e, circleInner[0], -30)
      })
    })

    function parallaxIt(e, target, movement) {
      let $this = $('.circle-inner'),
        relX = e.pageX - $this.offset().left,
        relY = e.pageY - $this.offset().top

      TweenMax.to(target, 1, {
        x: (relX - $this.width() / 2) / $this.width() * movement,
        y: (relY - $this.height() / 2) / $this.height() * movement,
      })
    }
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  sitemapToggle() {
    $(document).on('click', '.sitemap-toggle-js', function(e) {
      e.preventDefault()
      $(this).toggleClass('active')
      $(this)
        .find('i')
        .toggleClass('ion-navicon ion-ios-close-empty')
      $('.list-toggle-wrapper').slideToggle()
    })
  },
  smoothScroll(link, e) {
    let destination = 0
    if (e) e.preventDefault()
    let hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 120
      history.replaceState(null, null, hash)
    }

    if ($('.tasks-accordion').length && hash) {
      setTimeout(function() {
        let section = $('.tasks-accordion-item[data-id="' + hash + '"]'),
          headerHeight = $('header.banner').outerHeight()
        section.find('.tasks-accordion-item--title').trigger('click')
        $('html, body').animate(
          {
            scrollTop: section.offset().top - headerHeight,
          },
          600
        )
      }, 1000)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  scrollToNext() {
    $('.hero-section--scroll').click(function(e) {
      e.preventDefault()
      let cls = $(this)
        .closest('.vc_row')
        .next()
        .offset().top
      $('html, body').animate({ scrollTop: cls }, 'slow')
    })
  },
  scrollToTop() {
    $(document).on('click', '.scroll-top-js', function(e) {
      e.preventDefault()
      $('html, body').animate({ scrollTop: 0 }, 'slow')
    })
  },
  viewPortMobile() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  menuButton() {
    let widthLabel = $('.menu-js')
      .find('#menu-toggle + label.menu-toggle-text > span')
      .outerWidth()

    $('.menu-js').css('width', widthLabel)
  },
  sticky_anchors() {
    $('.hero-anchors-list').each(function(i) {
      if (i == 0) {
        let list = $(this).find('.hero-anchors--item')
        list.each(function() {
          let href = $(this)
              .find('.hero-anchors--link')
              .attr('href'),
            title = $(this)
              .find('.hero-anchors--link')
              .text()
          $('.sticky-anchors').append(
            `<li class="sticky-anchors--item"><a class="sticky-anchors--link" href="${href}">${title}</a></li>`
          )
        })
      }
    })
  },

  init() {
    this.setBodyWidth()
    this.sitemapToggle()
    this.scrollToNext()
    this.scrollToTop()
    this.parallaxCircle()
    this.viewPortMobile()
    this.sticky_anchors()
    this.menuButton()

    $(window).on('scroll', function() {
      if ($('body').hasClass('uber-active')) {
        $('body').removeClass('uber-active')
        $(
          '.ubermenu-items-align-left.ubermenu-horizontal .ubermenu-item.ubermenu-item-level-0'
        ).removeClass('ubermenu-active')
      }

      let scroll = $(window).scrollTop(),
        whiteSpace = 122

      if (scroll >= 500 && $('.dhsv_vc_contact_teaser').length == 0) {
        $('.scroll-sticky').fadeIn()
      } else {
        $('.scroll-sticky').fadeOut()
      }

      if (scroll >= 500) {
        $('.sticky-anchors .sticky-anchors--link').each(function() {
          let link = $(this)
          let section = $(link.attr('href'))

          if (
            section.position().top <= scroll + whiteSpace &&
            section.position().top + section.height() > scroll + whiteSpace
          ) {
            $('.sticky-anchors .sticky-anchors--link').removeClass('active')
            link.addClass('active')
          } else {
            link.removeClass('active')
          }
        })
        $('header.banner').addClass('active')
      } else {
        $('header.banner').removeClass('active')
      }
    })

    $(document).on('click', '.toggle-menu-js', function() {
      $('header.banner nav.nav-primary').fadeToggle()
      $('html').toggleClass('responsive-fixed')
    })
    $('.ubermenu .ubermenu-item.ubermenu-has-submenu-drop').on(
      'mouseover',
      function() {
        $('body').addClass('uber-active')
        setTimeout(function() {
          let heightMenu = $(
              '.ubermenu-active > .ubermenu-submenu'
            ).outerHeight(),
            heightHeader = $('header.banner').outerHeight()

          $('.ubermenu-bg').css({ maxHeight: heightMenu + heightHeader + 200 })
        }, 300)
      }
    )

    $('.ubermenu, .ubermenu .ubermenu-item.ubermenu-item-level-0').on(
      'mouseleave',
      function() {
        $('body').removeClass('uber-active')
      }
    )
    $(
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])'
    ).on('click', function(e) {
      dhsvCommon.smoothScroll($(this).attr('href'), e)
    })
    if (window.location.hash) {
      dhsvCommon.smoothScroll(window.location.hash)
    }
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
      this.viewPortMobile()
      this.menuButton()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
