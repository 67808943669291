import $ from 'jquery'
import Flickity from 'flickity'
import 'jquery-match-height'

$(document).ready(() => {
  dhsv_vc_main_slider()
})

function dhsv_vc_main_slider() {
  $('.dhsv_vc_items_slider .items-slider').each((i, el) => {
    const flkty = new Flickity(el, {
      draggable: true,
      bgLazyLoad: true,
      contain: false,
      pageDots: false,
      prevNextButtons: true,
      arrowShape: '',
      groupCells: 1,
      wrapAround: true,
      on: {
        ready: function() {
          $('.dhsv_vc_items_slider .h1').matchHeight()
          let url = $('.dhsv_vc_items_slider .items-slider')
            .find('.is-selected')
            .data('url')
          $('.item-slider--next-button').attr('href', url)
        },
        change: function(index) {
          let url = $('.dhsv_vc_items_slider .items-slider .item')
            .eq(index)
            .data('url')
          $('.item-slider--next-button').attr('href', url)
        },
      },
    })

    let circle = document.querySelector('circle')

    let radius = circle.r.baseVal.value
    let circumference = radius * 2 * Math.PI

    circle.style.strokeDasharray = `${circumference} ${circumference}`
    circle.style.strokeDashoffset = `${circumference}`

    function setProgress(percent) {
      const offset = circumference - percent / 100 * circumference
      circle.style.strokeDashoffset = offset
    }

    // elements
    let $cellButtonGroup = $('.items-slider-titles')
    //let $cellButtons = $cellButtonGroup.find('.items-slider--bullet')

    // update selected cellButtons
    flkty.on('select', function() {
      $cellButtonGroup.each(function() {
        console.log(flkty.selectedIndex) //eslint-disable-line
        $(this)
          .find('.items-slider--bullet')
          .removeClass('is-selected')
        $(this)
          .find('.items-slider--bullet')
          .eq(flkty.selectedIndex)
          .addClass('is-selected')
      })
    })

    // select cell on button click
    $cellButtonGroup.on('click', '.items-slider--bullet', function() {
      let index = $(this).index()
      flkty.select(index)
      resetProgressbar()
      percentTime = 0
      isPause = false
      tick = setInterval(interval, 10)
      let url = $('.dhsv_vc_items_slider .items-slider .is-selected')
        .eq(index)
        .data('url')
      $('.item-slider--next-button').attr('href', url)
    })

    $(window).on('resize', () => {
      $(el)
        .find('.flickity-viewport')
        .css('height', '')
      flkty.resize()
    })

    let time = 6
    let $bar, isPause, tick, percentTime

    $bar = $('.slider-progress .progress')

    // $('.dhsv_vc_items_slider').on({
    //   mouseenter: function() {
    //     isPause = true
    //   },
    //   mouseleave: function() {
    //     isPause = false
    //   },
    // })

    function startProgressbar() {
      resetProgressbar()
      percentTime = 0
      isPause = false
      tick = setInterval(interval, 10)
    }

    function interval() {
      if (isPause === false) {
        percentTime += 1 / (time + 0.1)
        $bar.css({
          width: percentTime + '%',
        })
        setProgress(Math.round(percentTime))
        if (percentTime >= 100) {
          flkty.next()
          startProgressbar()
        }
      }
    }

    function resetProgressbar() {
      $bar.css({
        width: 0 + '%',
      })
      clearTimeout(tick)
    }

    startProgressbar()

    // function setFadedSlide(slider, index) {
    //   if (slider.cells.length > 3) {
    //     slider.cells.forEach(el => {
    //       $(el.element).removeClass('is-faded')
    //     })
    //     const inactiveIndex =
    //       index + 3 > slider.cells.length - 1
    //         ? index + 3 - slider.cells.length
    //         : index + 3
    //     $(slider.cells[inactiveIndex].element).addClass('is-faded')
    //   }
    // }
  })
}
