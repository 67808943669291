import $ from 'jquery'

$(document).ready(dhsv_vc_tasks_accordion())

function dhsv_vc_tasks_accordion() {
  $('.tasks-accordion-item--title').on('click', function(e) {
    e.preventDefault()
    window.location.hash = $(this)
      .parents('.tasks-accordion-item')
      .attr('data-id')
    // let destination = 0,
    //   hash = window.location.hash
    // destination = $(hash).offset().top - 120

    // $('html, body').animate(
    //   {
    //     scrollTop: destination,
    //   },
    //   'slow'
    // )

    $(this)
      .parents('.tasks-accordion-item')
      .find('.tasks-accordion-item--text')
      .slideToggle()
    $(this)
      .parents('.tasks-accordion-item')
      .find('.ion')
      .toggleClass('ion-ios-plus-empty ion-ios-minus-empty')
  })
}
