import $ from 'jquery'
import Flickity from 'flickity'
import 'flickity-fade'

$(document).ready(() => {
  dhsv_vc_taskgroup_slider()
})

function dhsv_vc_taskgroup_slider() {
  $('.dhsv_vc_taskgroup_teaser').each(function(ind) {
    $(this)
      .find('.taskgroup-teaser-list .taskgroup-teaser-list__item')
      .on('click', function() {
        let ind = $(this).index()

        $(this)
          .parents('.taskgroup-teaser-tabs')
          .find('.taskgroup-teaser-list__item')
          .removeClass('active')
        $(this)
          .parents('.taskgroup-teaser-tabs')
          .find('.taskgroup-teaser-list__item')
          .eq(ind)
          .addClass('active')

        $(this)
          .parents('.taskgroup-teaser-tabs')
          .find('.taskgroup-teaser-images__item')
          .removeClass('active')
        $(this)
          .parents('.taskgroup-teaser-tabs')
          .find('.taskgroup-teaser-images__item')
          .eq(ind)
          .addClass('active')
      })

    let slider = $(this).find('.taskgroup-teaser-js')[0],
      sliderNav = $(this).find('.taskgroup-teaser-nav')[0]
    const flkty = new Flickity(slider, {
      draggable: '>1',
      asNavFor: sliderNav,
      wrapAround: true,
      pageDots: false,
      fade: true,
      adaptiveHeight: false,
      on: {
        change: function(index) {
          flktyNav.select(index)
          $('.dhsv_vc_taskgroup_teaser')
            .eq(ind)
            .find('.taskgroup-teaser__image')
            .removeClass('active')
          $('.dhsv_vc_taskgroup_teaser')
            .eq(ind)
            .find('.taskgroup-teaser__image')
            .eq(index)
            .addClass('active')

          // $('.dhsv_vc_taskgroup_teaser')
          //   .eq(ind)
          //   .find('.taskgroup-teaser__header')
          //   .removeClass('active')
          // $('.dhsv_vc_taskgroup_teaser')
          //   .eq(ind)
          //   .find('.taskgroup-teaser__header')
          //   .eq(index)
          //   .addClass('active')
        },
      },
    })
    flkty

    const flktyNav = new Flickity(sliderNav, {
      draggable: true,
      contain: true,
      pageDots: false,
      cellAlign: 'left',
      adaptiveHeight: false,
      on: {
        change: function(index) {
          flktyNav.select(index)
          $('.dhsv_vc_taskgroup_teaser')
            .eq(ind)
            .find('.taskgroup-teaser__image')
            .removeClass('active')
          $('.dhsv_vc_taskgroup_teaser')
            .eq(ind)
            .find('.taskgroup-teaser__image')
            .eq(index)
            .addClass('active')
        },
      },
    })
    flktyNav

    $(window).on('resize', () => {
      $(this)
        .find('.taskgroup-teaser .flickity-viewport')
        .css('height', '')
      flkty.resize()
      $(this)
        .find('.taskgroup-teaser-nav .flickity-viewport')
        .css('height', '')
      flktyNav.resize()
    })

    let circle = $(this).find('.progress-ring__circle'),
      radius,
      circumference

    if (circle.length) {
      radius = circle[0].r.baseVal.value
      circumference = radius * 2 * Math.PI

      circle[0].style.strokeDasharray = `${circumference} ${circumference}`
      circle[0].style.strokeDashoffset = `${circumference}`
    }

    $(this)
      .find('.taskgroup-teaser-nav .next')
      .on('click', function() {
        flkty.next()
        resetProgressbar()
        percentTime = 0
        tick = setInterval(interval, 20)
      })

    $(this)
      .find('.taskgroup-teaser-nav .previous')
      .on('click', function() {
        flkty.previous()
        resetProgressbar()
        percentTime = 0
        tick = setInterval(interval, 20)
      })

    $(this)
      .find('.taskgroup-teaser-nav .taskgroup-teaser-nav__item')
      .on('click', function() {
        let index = $(this).index()
        flkty.select(index)
        flktyNav.select(index)
        resetProgressbar()
        percentTime = 0
        tick = setInterval(interval, 20)
      })

    function setProgress(percent) {
      if (circle.length) {
        const offset = circumference - percent / 100 * circumference
        circle[0].style.strokeDashoffset = offset
      }
    }

    let time = 7
    let tick, percentTime

    function startProgressbar() {
      resetProgressbar()
      percentTime = 0
      tick = setInterval(interval, 20)
    }

    function interval() {
      percentTime += 1 / (time + 0.1)
      setProgress(Math.round(percentTime))
      if (percentTime >= 100) {
        flkty.next()
        startProgressbar()
      }
    }

    function resetProgressbar() {
      clearTimeout(tick)
    }

    startProgressbar()
  })
}
